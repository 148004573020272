<template>
  <div class="KnowledgeDetails-Formcard recent-activity-page">
    <h2>
      {{ content.message_data.data.title }}
      <template v-if="this.$route.name == 'Notification'">
        <custom-button
          title="Delete Activity"
          iconClass="far fa-trash-alt"
          className="notification-delete"
          @click.native="deleteMessage(content.unique_identifier)"
        />
      </template>
    </h2>
    <div class="message_content">
      <p v-html="contentBody"></p>
      <!-- <br />
      <div v-if="content.file && content.file.length > 0">
        <h6>Attachments:</h6>
        <ul style="list-style: none">
          <li v-for="file in content.file" :key="file.id">
            <a
              :href="knowledgeBaseDownloadUrl + file.path"
              target="__blank"
              download
            >
              <i class="fa fa-file"></i>
              {{ file.filename }}
            </a>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "NotificationContent",
  props: {
    content: {
      required: true,
    },
  },
  computed: {
    contentBody() {
      let string = this.content.message_data.data.body;
      return string.replaceAll("\n", "<br/>");
    },
  },
  methods: {
    deleteMessage(id) {
      const url = `v1/delete-notification/${id}`;
      this.$confirm({
        title: "Delete Activity",
        message: `Do you want to delete this activity?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
};
</script>
<style scoped>
.notification-delete {
  float: right;
  font-size: 18px !important;
  color: #5f6368;
  margin-top: -10px;
}
.recent-activity-page {
  min-height: 300px;
  padding-top: 35px;
}
.recent-activity-page h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  position: relative;
}
.recent-activity-page h2 a {
  position: absolute;
  top: -5px;
  right: 0;
}
.recent-activity-page p {
  line-height: 1.7;
  font-size: 15px;
  margin-bottom: 0;
}
</style>