var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "KnowledgeDetails-Formcard recent-activity-page"
  }, [_c('h2', [_vm._v(" " + _vm._s(_vm.content.message_data.data.title) + " "), this.$route.name == 'Notification' ? [_c('custom-button', {
    attrs: {
      "title": "Delete Activity",
      "iconClass": "far fa-trash-alt",
      "className": "notification-delete"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.deleteMessage(_vm.content.unique_identifier);
      }
    }
  })] : _vm._e()], 2), _c('div', {
    staticClass: "message_content"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.contentBody)
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }