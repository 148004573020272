var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    class: _vm.list.has_seen ? "list-".concat(_vm.list.unique_identifier, " seen") : "list-".concat(_vm.list.unique_identifier, " unseen")
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function click($event) {
        return _vm.clickHandler(_vm.list);
      }
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "dashknw-content"
  }, [_c('h3', [_vm._v(_vm._s(_vm._f("truncate")(_vm.list.message_data.data.title, 50)))]), this.$route.name != 'Notification' ? [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.contentBody(_vm.list.message_data.data.body))
    }
  })] : _vm._e(), _c('span', {
    staticClass: "msg-date"
  }, [_vm._v(" " + _vm._s(_vm.list.message_data.data.date) + " ")])], 2)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashknw-icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/user.svg"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }