var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper know-center-wrap notification-center-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _c('div', {
    staticClass: "KnowledgeDetails-wrap"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-xl-4 col-md-4 notifications-page-div"
  }, [_c('div', {
    staticClass: "KnowledgeDetails-card"
  }, [_c('div', {
    staticClass: "know-center-list"
  }, [_c('ul', _vm._l(_vm.notifications.records, function (k) {
    return _c('notification-list', {
      key: k.unique_identifier,
      attrs: {
        "list": k
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.showNotificationData(k);
        }
      }
    });
  }), 1)])]), _c('pagination', {
    attrs: {
      "rows": _vm.notifications,
      "filters": []
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.notifications = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-xxl-8 col-xl-8 col-md-8"
  }, [_c('notification-content', {
    attrs: {
      "content": _vm.showNotification
    },
    on: {
      "onSuccess": _vm.getNotifications
    }
  })], 1)])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Recent Activity")])]);
}]

export { render, staticRenderFns }