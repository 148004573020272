<template>
  <div class="page-wrapper know-center-wrap notification-center-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Recent Activity</h1>
      </div>

      <div class="KnowledgeDetails-wrap">
        <div class="row">
          <div class="col-xxl-4 col-xl-4 col-md-4 notifications-page-div">
            <div class="KnowledgeDetails-card">
              <div class="know-center-list">
                <ul>
                  <notification-list
                    v-for="k in notifications.records"
                    :key="k.unique_identifier"
                    :list="k"
                    @onClicked="showNotificationData(k)"
                  />
                </ul>
              </div>
            </div>
            <pagination :rows.sync="notifications" :filters="[]" />
          </div>
          <div class="col-xxl-8 col-xl-8 col-md-8">
            <notification-content
              :content="showNotification"
              @onSuccess="getNotifications"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import NotificationList from "@/views/Notification/partials/NotificationList";
import NotificationContent from "@/views/Notification/partials/NotificationContent";
export default {
  name: "Notification",
  components: {
    NotificationList,
    NotificationContent,
  },
  data: () => ({
    notifications: [],
    showNotification: null,
    currentItemId: null,
    breadcrumbItems: [{ title: "Recent Activity", active: true }],
  }),
  methods: {
    listClass(id) {
      let activeClass = this.currentItemId == id ? "active" : "";
      return `notification-list ${activeClass}`;
    },
    showNotificationData(row) {
      this.showNotification = row;
      this.currentItemId = row.id;
    },
    getNotifications() {
      const url = "v1/read-unread-notification?page_size=10";
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          this.notifications = res.data;
          this.showNotification = this.notifications.records[0];
          this.currentItemId = this.showNotification.unique_identifier;
        })
        .catch(() => {
          this.notifications = [];
          this.showNotification = null;
          this.currentItemId = null;
        })
        .finally(() => {
          loader.hide();
          if (this.notifications.records.length < 1) {
            this.$router.push({ name: "Dashboard" });
          }
        });
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>
<style src="@/assets/css/knowledge.css"></style>
<style>
.notifications-page-div {
  position: relative;
}
.notifications-page-div .KnowledgeDetails-card {
  padding-bottom: 60px;
}
.notifications-page-div .table-pagination {
  position: absolute;
  bottom: 0px;
  right: 32px;
}
.notifications-page-div .table-pagination ul li {
  font-size: 12px;
}
.notifications-page-div .table-pagination ul li button {
  padding: 5px;
}
.notifications-page-div .table-pagination ul li span {
  padding: 5px 2px;
}
@media (max-width: 992px) {
  .notifications-page-div .KnowledgeDetails-card {
    padding-bottom: 45px;
  }
  .notifications-page-div .table-pagination {
    bottom: 30px;
    right: 20px;
  }
}

/* @media (max-width: 768px) {
  .notifications-page-div .table-pagination {
    position: absolute;
    bottom: 100px;
  }
}

@media (max-width: 576px) {
  .notifications-page-div .table-pagination {
    bottom: 50px;
  }
} */
</style>