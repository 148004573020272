<template>
  <li
    :class="
      list.has_seen
        ? `list-${list.unique_identifier} seen`
        : `list-${list.unique_identifier} unseen`
    "
  >
    <a href="javascript:void(0);" @click="clickHandler(list)">
      <div class="dashknw-icon">
        <img src="@/assets/images/user.svg" alt="" />
      </div>
      <div class="dashknw-content">
        <h3>{{ list.message_data.data.title | truncate(50) }}</h3>
        <template v-if="this.$route.name != 'Notification'">
          <p v-html="contentBody(list.message_data.data.body)"></p>
        </template>
        <span class="msg-date">
          {{
            list.message_data.data.date
          }}
        </span>
      </div>
    </a>
  </li>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "NotificationList",
  props: {
    list: {
      required: true,
    },
  },
  methods: {
    contentBody(string) {
      return string.replaceAll("\n", "<br/>");
    },
    clickHandler(message) {
      if (message.has_seen != 1) {
        let element = document.querySelector(
          `.list-${message.unique_identifier}`
        );
        const url = `v1/notification-seen-status?message_id=${message.unique_identifier}`;
        Api.put(url).then(() => {
          element.classList.remove("unseen");
          element.classList.add("seen");
        });
      }
      this.$emit("onClicked");
    },
  },
};
</script>
<style scoped>
.unseen {
  background: rgb(220 229 236 / 58%);
  transition: all ease 0.4s;
}
</style>